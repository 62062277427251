import React from 'react';
import { Button, Page, Form, Input } from 'src/common';
import Select from 'src/common/Select';
import { lossReporterRoles } from 'src/lib/constants';
import { useModel, useForm } from 'src/lib/hooks';
import { validateSelectOptionValid } from 'src/lib/validations';

const WhoIsReporting = ({ prevPage, nextPage }) => {
  const {
    loss: { reportedBy },
  } = useModel.fnol();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      loss: { reportedBy },
    },
  });

  const lossReporter = watch('loss.reportedBy.participantRole');

  const onSubmit = handleSubmit(async (data) => {
    if (lossReporter === 'Policy Holder') {
      delete data.loss.reportedBy.participantDescription;
    }
    await nextPage(data);
  });

  const registerParticipantRole = register('loss.reportedBy.participantRole', {
    required: 'Required',
    validate: (value) =>
      validateSelectOptionValid(
        lossReporterRoles,
        (option) => option.id === value
      ),
  });

  return (
    <Page stage="Let's Get Started">
      <Page.Section>
        <Form onSubmit={onSubmit}>
          <Select
            label="Who is reporting the Loss?"
            options={lossReporterRoles}
            {...registerParticipantRole}
            onChange={(e) => {
              registerParticipantRole.onChange(e);
              setValue('loss.reportedBy.participantDescription', '');
            }}
            error={errors?.loss?.reportedBy?.participantRole?.message}
          />
          {lossReporter === 'Other' && (
            <Input
              label="Relationship with the Policy Holder"
              {...register('loss.reportedBy.participantDescription', {
                required: 'Required',
              })}
              error={errors?.loss?.reportedBy?.participantDescription?.message}
            />
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="light"
          onClick={prevPage}
          type="button"
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="violet"
          type="submit"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

WhoIsReporting.displayName = 'WhoIsReporting';

export default WhoIsReporting;
