const toBoolean = (stringValue) => {
  return String(stringValue).toLowerCase() === 'true';
};

let config = {
  debug: toBoolean(process.env.REACT_APP_DEBUG),
  a11y: process.env.REACT_APP_A11Y,
  sessionTime: process.env.REACT_APP_SESSION_TIME || 35,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_BASEURL,
    clientID: process.env.REACT_APP_API_CLIENT_ID,
    clientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
  },

  phc: {
    baseUrl: process.env.REACT_APP_PHC_BASEURL,
  },
  melissaData: {
    licenseKey: process.env.REACT_APP_MELISSADATA_LICENSEKEY,
    expressEntryEndpoint:
      process.env.REACT_APP_MELISSADATA_EXPRESSENTRYENDPOINT,
  },
  files: {
    maxNumber: 10,
    videoSize: 500,
    otherSize: 10,
  },
  maintenanceMode: toBoolean(process.env.REACT_APP_USE_MAINTENANCE_MODE),
  enableFnolSelectionForResumption: toBoolean(
    process.env.REACT_APP_ALLOW_SELECTION_FOR_RESUMPTION
  ),
  enablePhcFnolIntegration: toBoolean(
    process.env.REACT_APP_ENABLE_PHC_INTEGRATION
  ),
};

export default config;
