import React from 'react';
import { Button, Checkbox, FilePreview, Form, Page, Error } from 'src/common';
import analytics, { EVENTS } from 'src/lib/analytics';
import { useModel, useForm } from 'src/lib/hooks';
import Details from './Details';
import { lossMapping } from 'src/lib/constants';
import styles from './Review.module.css';

const isDamagesDisplayed = (typeOfLoss, causeOfLoss) => {
  const typeOfLossTitle = lossMapping.types.find(
    (x) => x.id === 'Other - Property Damage'
  )?.title;

  const causeOfLossTitle = lossMapping.causes[typeOfLoss].find(
    (x) => x.id === 'Pandemic'
  )?.title;

  return !(typeOfLossTitle === typeOfLoss && causeOfLossTitle === causeOfLoss);
};

const Review = ({ prevPage, nextPage }) => {
  const fnol = useModel.fnol();
  const { submit } = useModel.fnol.dispatch();
  const {
    typeOfLoss,
    causeOfLoss,
    damages,
    preferredLanguage,
    attachments,
    areTranslationServicesNeeded,
    loss,
    catastrophe,
    writtenEstimate,
    hiredContractor,
  } = fnol;

  const {
    register,
    handleSubmit,
    watch,
    formError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = handleSubmit(async () => {
    await submit();
    analytics.track(EVENTS.CLAIM_SUBMITTED, fnol);
    await nextPage(null, { skipUpdate: true });
  });

  const understandStatement = watch('understandStatement');
  const reportByText =
    loss.reportedBy.participantRole === 'Other'
      ? `${loss.reportedBy.participantRole} - ${loss.reportedBy.participantDescription}`
      : loss.reportedBy.participantRole;
  return (
    <Page
      stage="Almost there..."
      subtitle="If you are ready to send the information to us for review, click “Submit 
    Claim”. Alternatively, you may use the “Go Back” button to return to any of
    the previous steps and add information, photographs, or videos."
    >
      <div className={styles.sections}>
        <Page.Section title="Tell Us About Your Incident">
          <Details>
            <Details.Row>
              <Details.Item label="Damage Type" value={typeOfLoss} />
              <Details.Item label="Loss Cause" value={causeOfLoss} />
            </Details.Row>
            <Details.Row>
              {/* Review this information once backend is implemented*/}
              <Details.Item
                label="Incident Address"
                value={`${loss.address.street}, ${loss.address.city}, ${loss.address.state} ${loss.address.zip}`}
              />
              <Details.Item label="Incident Location" value={loss?.location} />
            </Details.Row>
            <Details.Item label="Catastrophe" value={catastrophe} />
            <Details.Item
              label="Do you have a written estimate?"
              value={writtenEstimate}
            />
            <Details.Item
              label="Have you hired a contractor in relation to this claim?"
              value={hiredContractor}
            />
            <Details.Item
              label="Description of the incident / What happened?"
              value={loss?.description}
            />
          </Details>
          {/* Review this information once backend is implemented*/}
        </Page.Section>

        {isDamagesDisplayed(typeOfLoss, causeOfLoss) && (
          <Page.Section title="Tell Us About the Damages">
            {!!damages ? (
              <Details>
                {damages?.wasSystemOrApplianceMalfunction && (
                  <Details.Item
                    label="Was the loss the result of a system or appliance malfunction?"
                    value={damages?.wasSystemOrApplianceMalfunction}
                  />
                )}
                {damages?.howOldIsTheSystemMalfunctioned && (
                  <Details.Item
                    label="How old is the system or appliance that malfunctioned?"
                    value={damages?.howOldIsTheSystemMalfunctioned}
                  />
                )}
                {damages?.isThereAnOpeningInTheRoofOrStructure && (
                  <Details.Item
                    label="Is there an opening in the roof or structure?"
                    value={damages?.isThereAnOpeningInTheRoofOrStructure}
                  />
                )}
                {damages?.isTreeRemovalNeeded && (
                  <Details.Item
                    label="Is tree removal needed?"
                    value={damages?.isTreeRemovalNeeded}
                  />
                )}
                {damages?.isEmergencyTarpingNeeded && (
                  <Details.Item
                    label="Do you need emergency tarping?"
                    value={damages?.isEmergencyTarpingNeeded}
                  />
                )}
                {damages?.isEmergencyBoardUpNeeded && (
                  <Details.Item
                    label="Do you need emergency board up?"
                    value={damages?.isEmergencyBoardUpNeeded}
                  />
                )}
                {damages?.haveInteriorDamages && (
                  <Details.Item
                    label="Do you have interior damage?"
                    value={damages?.haveInteriorDamages}
                  />
                )}
                {damages?.areWallsFloorsWet && (
                  <Details.Item
                    label="Are the walls or floors wet?"
                    value={damages?.areWallsFloorsWet}
                  />
                )}
                {damages?.wasWaterMitigationCompanyContacted && (
                  <Details.Item
                    label="Has a water mitigation company already been contacted?"
                    value={damages?.wasWaterMitigationCompanyContacted}
                  />
                )}
                {damages?.whoReferredProvider && (
                  <Details.Item
                    label="Who referred you to this provider?"
                    value={damages.whoReferredProvider}
                  />
                )}
                {damages?.areEmergencyWaterRemovalServicesNeeded && (
                  <Details.Item
                    label="Do you need emergency water removal or drying services?"
                    value={damages?.areEmergencyWaterRemovalServicesNeeded}
                  />
                )}
                {damages?.numberOfRoomsDamaged && (
                  <Details.Item
                    label="How many rooms were damaged?"
                    value={damages?.numberOfRoomsDamaged}
                  />
                )}
                {damages?.whatIsYourRoofMaterial && (
                  <Details.Item
                    label="What type of material is your roof?"
                    value={damages?.whatIsYourRoofMaterial}
                  />
                )}
                {damages?.wasAnyoneElsePropertyDamaged && (
                  <Details.Item
                    label="Was anyone else's property damaged?"
                    value={damages?.wasAnyoneElsePropertyDamaged}
                  />
                )}
                {damages?.wasAnybodyInjured && (
                  <Details.Item
                    label="Was anybody injured?"
                    value={damages?.wasAnybodyInjured}
                  />
                )}
                {damages?.wasThisAFatality &&
                  damages?.wasAnybodyInjured === 'Yes' && (
                    <Details.Item
                      label="Was this a fatality?"
                      value={damages?.wasThisAFatality}
                    />
                  )}
                {damages?.wasInjuredAMinor &&
                  damages?.wasAnybodyInjured === 'Yes' && (
                    <Details.Item
                      label="Was the person injured a minor?"
                      value={damages?.wasInjuredAMinor}
                    />
                  )}
                {damages?.injuriesDescription &&
                  damages?.wasAnybodyInjured === 'Yes' && (
                    <Details.Item
                      label="Describe the injuries."
                      value={damages?.injuriesDescription}
                    />
                  )}
                {damages?.canPropertyBeOccupied && (
                  <Details.Item
                    label="Can the property be occupied?"
                    value={damages?.canPropertyBeOccupied}
                  />
                )}
                {(damages?.additionalComments ||
                  damages?.additionalComments === '') && (
                  <Details.Item
                    label="Additional Comments"
                    value={damages?.additionalComments || 'None'}
                  />
                )}
              </Details>
            ) : (
              'No additional information provided.'
            )}
          </Page.Section>
        )}
        <Page.Section title="Reported By">
          <Details>
            <Details.Item
              label="Who is reporting the loss?"
              value={reportByText}
            />
          </Details>
        </Page.Section>
        <Page.Section title="Photos, Videos and Documents">
          {attachments?.length > 0 ? (
            <FilePreview files={attachments} />
          ) : (
            'None'
          )}
        </Page.Section>
        <Page.Section title="Preferred Language">
          <Details>
            <Details.Item
              label="Do you need translation services to effectively communicate with your adjuster?"
              value={areTranslationServicesNeeded}
            />
            <Details.Item
              label="Preferred Language"
              value={preferredLanguage}
            />
          </Details>
        </Page.Section>
        <Page.Section>
          {formError && <Error>{formError}</Error>}
          <div className={styles.information}>
            <p>
              I acknowledge that I have read and answered all questions within
              this online claims submission.
            </p>
            <p>
              FRAUD STATEMENT: ANY PERSON WHO KNOWINGLY AND WITH THE INTENT TO
              INJURE, DEFRAUD, OR DECEIVE ANY INSURER FILES A STATEMENT OF CLAIM
              OR AN APPLICATION CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING
              INFORMATION IS GUILTY OF A FELONY OF THE THIRD DEGREE.
            </p>
          </div>
          <Form>
            <div className={styles.checkbox}>
              <Checkbox
                label="I have read and understood the above statement."
                {...register('understandStatement', { required: 'Required' })}
                error={errors?.understandStatement?.message}
              />
            </div>
          </Form>
        </Page.Section>
      </div>
      <Page.Buttons>
        <Button
          type="button"
          color="light"
          onClick={prevPage}
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="violet"
          onClick={onSubmit}
          disabled={!understandStatement}
          loading={isSubmitting}
        >
          Submit Claim
        </Button>
      </Page.Buttons>
    </Page>
  );
};

Review.displayName = 'Review';
export default Review;
