import { gStateFlHi, gStateFlHi2, gStateFlHi3 } from 'src/assets/img';

const lossMapping = {
  types: [
    {
      id: 'Weather',
      title: 'Weather',
    },
    {
      id: 'Water',
      title: 'Water',
    },
    {
      id: 'Other - Property Damage',
      title: 'Other - Property Damage',
    },
    {
      id: 'Fire',
      title: 'Fire',
    },
    {
      id: 'Liability',
      title: 'Liability',
    },
    {
      id: 'Malicious Acts',
      title: 'Malicious Acts',
    },
  ],
  causes: {
    'Weather': [
      { id: 'Flood', title: 'Flood' },
      { id: 'Hail', title: 'Hail' },
      { id: 'Hurricane', title: 'Hurricane' },
      { id: 'Tropical Storm', title: 'Tropical Storm' },
      { id: 'Freezing Ice Dam', title: 'Freezing Ice Dam' },
      { id: 'Freezing Pipes', title: 'Freezing Pipes' },
      {
        id: 'Freezing Structure/Slab/Foundation',
        title: 'Freezing Structure/Slab/Foundation',
      },
      { id: 'Lightning', title: 'Lightning' },
      { id: 'Roof Leak', title: 'Roof Leak' },
      { id: 'Tornado', title: 'Tornado' },
      { id: 'Volcanic Action', title: 'Volcanic Action' },
      {
        id: 'Weight of Ice or Snow',
        title: 'Weight of Ice or Snow',
      },
      {
        id: 'Windstorm Other than Hurricane',
        title: 'Windstorm Other than Hurricane',
      },
    ],
    'Water': [
      { id: 'Appliance Leak', title: 'Appliance Leak' },
      { id: 'Mold', title: 'Mold' },
      { id: 'Overflow', title: 'Overflow' },
      { id: 'Pipe Break', title: 'Pipe Break' },
      { id: 'Sewer Backup', title: 'Sewer Backup' },
      { id: 'Shower Pan', title: 'Shower Pan' },
      { id: 'Water - Other', title: 'Water - Other' },
    ],
    'Other - Property Damage': [
      { id: 'Collapse', title: 'Collapse' },
      { id: 'Collision', title: 'Collision' },
      { id: 'Earthquake', title: 'Earthquake' },
      { id: 'Equipment Breakdown', title: 'Equipment Breakdown' },
      { id: 'Explosion', title: 'Explosion' },
      { id: 'Falling Objects', title: 'Falling Objects' },
      { id: 'Flooring/Tile', title: 'Flooring/Tile' },
      { id: 'Food Spoilage', title: 'Food Spoilage' },
      { id: 'Insect/Vermin/Animal', title: 'Insect/Vermin/Animal' },
      { id: 'Loss Assessment', title: 'Loss Assessment' },
      { id: 'Mudslide', title: 'Mudslide' },
      { id: 'Mandatory Evacuation', title: 'Mandatory Evacuation' },
      { id: 'Other', title: 'Other' },
      { id: 'Pandemic', title: 'Pandemic' },
      { id: 'Power Surge/Failure', title: 'Power Surge/Failure' },
      { id: 'Recreational Vehicles', title: 'Recreational Vehicles' },
      { id: 'Settling/Cracking', title: 'Settling/Cracking' },
      { id: 'Sinkhole', title: 'Sinkhole' },
    ],
    'Fire': [
      { id: 'Appliance Fire', title: 'Appliance Fire' },
      { id: 'Fire', title: 'Fire' },
      { id: 'Grease Fire', title: 'Grease Fire' },
      { id: 'Smoke and Soot', title: 'Smoke and Soot' },
      { id: 'Total Fire', title: 'Total Fire' },
    ],
    'Liability': [
      { id: 'Animal', title: 'Animal' },
      { id: 'Bodily Injury', title: 'Bodily Injury' },
      { id: 'Other Liability', title: 'Other Liability' },
      { id: 'Property Damage', title: 'Property Damage' },
    ],
    'Malicious Acts': [
      { id: 'Burglary', title: 'Burglary' },
      { id: 'Identity Theft/Fraud', title: 'Identity Theft/Fraud' },
      { id: 'Riot and Civil Commotion', title: 'Riot and Civil Commotion' },
      { id: 'Terrorism', title: 'Terrorism' },
      { id: 'Theft', title: 'Theft' },
      {
        id: 'Vandalism/Malicious Mischief',
        title: 'Vandalism/Malicious Mischief',
      },
    ],
  },
};

const lossLocations = [
  { id: 'Primary Location', title: 'Primary Location' },
  { id: 'Other', title: 'Other' },
];

const lossReporterRoles = [
  { id: 'Policy Holder', title: 'Policy Holder' },
  { id: 'Other', title: 'Other' },
];

const languages = [
  {
    id: 'Spanish',
    title: 'Spanish',
  },
  {
    id: 'English',
    title: 'English',
  },
  {
    id: 'French',
    title: 'French',
  },
  {
    id: 'Creole',
    title: 'Creole',
  },
  {
    id: 'Chinese',
    title: 'Chinese',
  },
  {
    id: 'Mandarin',
    title: 'Mandarin',
  },
  {
    id: 'Cantonese',
    title: 'Cantonese',
  },
  {
    id: 'Arabic',
    title: 'Arabic',
  },
  {
    id: 'German',
    title: 'German',
  },
  {
    id: 'Russian',
    title: 'Russian',
  },
  {
    id: 'Hindi',
    title: 'Hindi',
  },
  {
    id: 'Portuguese',
    title: 'Portuguese',
  },
  {
    id: 'Vietnamese',
    title: 'Vietnamese',
  },
  {
    id: 'Italian',
    title: 'Italian',
  },
  {
    id: 'Korean',
    title: 'Korean',
  },
  {
    id: 'Japanese',
    title: 'Japanese',
  },
  {
    id: 'Other',
    title: 'Other',
  },
];

const whoReferredYou = [
  { id: 'Friend/Relative', title: 'Friend/Relative' },
  { id: 'Advertising', title: 'Advertising' },
  { id: 'Public Adjuster', title: 'Public Adjuster' },
  { id: 'Attorney', title: 'Attorney' },
  { id: 'Plumber/Contractor', title: 'Plumber/Contractor' },
];

const roofMaterials = [
  { id: 'Shingle', title: 'Shingle' },
  { id: 'Rolled Roof', title: 'Rolled Roof' },
  { id: 'Other', title: 'Other' },
];

const howOldIsTheSystem = [
  { id: 'Less than 10 years old', title: 'Less than 10 years old' },
  { id: 'Greater than 10 years old', title: 'Greater than 10 years old' },
  { id: 'Unknown', title: 'Unknown' },
];

const roomsDamaged = [
  { id: 'None', title: 'None' },
  { id: '1-2', title: '1-2' },
  { id: '3-6', title: '3-6' },
  { id: '7-10', title: '7-10' },
  { id: 'All', title: 'All' },
];

const acceptedFileTypes = [
  { ext: 'jpg', mime: 'image/jpg', icon: 'fileImage' },
  { ext: 'jpeg', mime: 'image/jpeg', icon: 'fileImage' },
  { ext: 'png', mime: 'image/png', icon: 'fileImage' },
  { ext: 'mp4', mime: 'video/mp4', icon: 'fileVideo' },
  { ext: 'mov', mime: 'video/quicktime', icon: 'fileVideo' },
  { ext: 'wmv', mime: 'video/x-ms-wmv', icon: 'fileVideo' },
  { ext: 'avi', mime: 'video/avi', icon: 'fileVideo' },
  { ext: 'wav', mime: 'audio/wav', icon: 'fileAudio' },
  { ext: 'mp3', mime: 'audio/mpeg', icon: 'fileAudio' },
  {
    ext: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'fileWord',
  },
  {
    ext: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: 'fileExcel',
  },
  { ext: 'pdf', mime: 'application/pdf', icon: 'filePdf' },
];

const commonStateFlHi = [
  { path: gStateFlHi, name: 'imgFlHi' },
  { path: gStateFlHi2, name: 'imgFlHi2' },
  { path: gStateFlHi3, name: 'imgFlHi3' },
];

const guidanceImagesStates = {
  FL: {
    title: 'Florida',
    images: commonStateFlHi,
  },
};

const unsupportedFile = {
  ext: '*',
  mime: 'unsupported/*',
  icon: 'fileUnsupported',
};

const causeOfLossQuestionsMapping = {
  'Flood': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Hail': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    wasWaterMitigationCompanyContacted: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    whoReferredProvider: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Hurricane': {
    isEmergencyTarpingNeeded: true,
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Tropical Storm': {
    isEmergencyTarpingNeeded: true,
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Freezing Ice Dam': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Lightning': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Roof Leak': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Tornado': {
    isEmergencyTarpingNeeded: true,
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Volcanic Action': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Weight of Ice or Snow': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Windstorm Other than Hurricane': {
    isEmergencyTarpingNeeded: true,
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Freezing Pipes': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Freezing Structure/Slab/Foundation': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    additionalComments: true,
  },
  'Mold': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
  },
  'Appliance Leak': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Sewer Backup': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Overflow': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Water - Other': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Pipe Break': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Shower Pan': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Collapse': {
    haveInteriorDamages: true,
    isEmergencyTarpingNeeded: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    wasAnyoneElsePropertyDamaged: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Collision': {
    haveInteriorDamages: true,
    isEmergencyBoardUpNeeded: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
    wasAnyoneElsePropertyDamaged: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Equipment Breakdown': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Explosion': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Falling Objects': {
    isEmergencyTarpingNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Flooring/Tile': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Food Spoilage': {
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
  },
  'Insect/Vermin/Animal': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Loss Assessment': { additionalComments: true },
  'Mandatory Evacuation': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Other': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Power Surge/Failure': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
  },
  'Recreational Vehicles': {
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Settling/Cracking': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Mudslide': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Earthquake': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Sinkhole': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Appliance Fire': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Fire': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Grease Fire': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Smoke and Soot': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Total Fire': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    additionalComments: true,
  },
  'Animal': {
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Bodily Injury': {
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Other Liability': {
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Property Damage': {
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Burglary': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Theft': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  'Identity Theft/Fraud': {
    additionalComments: true,
  },
  'Riot and Civil Commotion': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
  },
  'Terrorism': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
  },
  'Vandalism/Malicious Mischief': {
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    whatIsYourRoofMaterial: true,
    canPropertyBeOccupied: true,
    additionalComments: true,
  },
  '_testAll': {
    isEmergencyTarpingNeeded: true,
    isEmergencyBoardUpNeeded: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    wasAnyoneElsePropertyDamaged: true,
    wasWaterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    isThereAnOpeningInTheRoofOrStructure: true,
    isTreeRemovalNeeded: true,
    whatIsYourRoofMaterial: true,
  },
};

const generalCard = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  email: 'Email',
};

const companyCard = {
  ...generalCard,
  companyName: { label: 'Company Name', key: 'companyName' },
};

const attorneyCard = {
  ...generalCard,
  firstName: 'Attorney First Name',
  lastName: 'Attorney Last Name',
  companyName: { label: 'Firm Name', key: 'firmName' },
};

const insuranceCard = {
  ...companyCard,
  accountNumber: {
    label: 'Adverse Carrier Claim #',
    key: 'adverseCarrierClaimNumber',
  },
  clientFirstName: {
    label: 'Adverse First Name',
    key: 'adverseClientFirstName',
  },
  clientLastName: {
    label: 'Adverse Last Name',
    key: 'adverseClientLastName',
  },
};

const mortgageeCard = {
  ...companyCard,
  accountNumber: {
    label: 'Mortgagee Account #',
    key: 'mortgageeAccountNumber',
  },
  clientFirstName: {
    label: 'Client First Name',
    key: 'adverseClientFirstName',
  },
  clientLastName: {
    label: 'Client Last Name',
    key: 'adverseClientLastName',
  },
};

const contactFields = {
  'Additional Insured': generalCard,
  'Agent': generalCard,
  'Animal Owner': generalCard,
  'Assignment of Benefits': companyCard,
  'Attorney': attorneyCard,
  'Beneficiary': generalCard,
  'Claimant': generalCard,
  'Contractor / Repair': companyCard,
  'Driver': generalCard,
  'Emergency Service/Water Mitigation': companyCard,
  'Employee': companyCard,
  'Estate/Trustee': generalCard,
  'Insurance Company': insuranceCard,
  'Mortgagee': mortgageeCard,
  'Other Carrier': insuranceCard,
  'Other Representative Entity': attorneyCard,
  'Parent/Guardian': generalCard,
  'Power of Attorney': generalCard,
  'Property Manager': companyCard,
  'Public Adjuster': companyCard,
  'Recovery Counsel': attorneyCard,
  'Responsible Party': generalCard,
  'Responsible Party Attorney': attorneyCard,
  'Responsible Party Carrier': insuranceCard,
  'Witness': generalCard,
};

export {
  lossMapping,
  lossReporterRoles,
  languages,
  lossLocations,
  whoReferredYou,
  roomsDamaged,
  howOldIsTheSystem,
  acceptedFileTypes,
  guidanceImagesStates,
  unsupportedFile,
  causeOfLossQuestionsMapping,
  contactFields,
  roofMaterials,
};
