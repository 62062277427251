import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getPersistor } from '@rematch/persist';

import store from 'src/lib/store';
import config from 'src/config';
import { Loading } from 'src/common';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (config.a11y === 'true') {
  import('@axe-core/react').then(({ default: axe }) => {
    setInterval(() => axe(React, ReactDOM, 1000), 1000);
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Router>
        <PersistGate loading={<Loading />} persistor={getPersistor()}>
          <App />
        </PersistGate>
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
