import React from 'react';
import dayjs from 'dayjs';
import styles from './Footer.module.css';
import { slideLogo } from 'src/assets/img';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.divider}>
        <div className={styles.logoContainer}>
          <a href="https://www.slideinsurance.com/">
            <img
              src={slideLogo}
              alt="Slide logo"
              id="logo"
              className={styles.logo}
            />
          </a>
        </div>
        <h2 className={styles.text}>
          <span className={styles.heading}>Questions?</span>
          <span className={styles.heading}>
            Call Us{' '}
            <a href="tel:866-230-3758" className={styles.phone}>
              (866) 230-3758
            </a>
          </span>
        </h2>
        <div className={styles.message}>
          Content provided is intended for general informational purposes only.
          Any changes made to your policy may not be immediately reflected here.
          Please refer to your insurance policy for coverages, exclusions, and
          limitations.
        </div>
        <p className={styles.copy}>
          © {dayjs().year()} Slide Insurance Holdings, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
