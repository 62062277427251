import React, { useEffect } from 'react';
import merge from 'lodash.merge';
import {
  Select,
  Button,
  Page,
  TextArea,
  Form,
  Error,
  AutocompleteAddress,
  RadioInput,
} from 'src/common';
import { useModel, useForm } from 'src/lib/hooks';
import { lossMapping, lossLocations } from 'src/lib/constants';
import analytics, { EVENTS } from 'src/lib/analytics';
import Damages from '../Damages/Damages';

const LossDetails = ({ nextPage, isHistorical }) => {
  const { skip } = useModel.session.dispatch();
  const fnol = useModel.fnol();
  const defaultValues = useModel.fnol.defaults();
  const { pendingChanges } = useModel.session();
  const defaultSamePolicyAddress =
    JSON.stringify(defaultValues?.loss?.address) ===
    JSON.stringify(defaultValues?.policyHolder?.address);
  const form = useForm({
    defaultValues: {
      causeOfLoss: defaultValues.causeOfLoss,
      typeOfLoss: defaultValues.typeOfLoss,
      loss: {
        ...defaultValues.loss,
        address: defaultSamePolicyAddress ? null : defaultValues.loss.address,
      },
      samePolicyAddress: defaultSamePolicyAddress,
    },
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    setFormError,
    watch,
    setValue,
    register,
    formError,
    formState: { errors, isSubmitting },
    control,
  } = form;

  const typeOfLoss = watch('typeOfLoss');
  const causeOfLoss = watch('causeOfLoss');
  const otherLocation = watch('loss.location') === 'Other';
  setValue('samePolicyAddress', !otherLocation);

  useEffect(() => {
    skip({
      [Damages.displayName]: causeOfLoss === 'Pandemic',
    });
  }, [causeOfLoss, skip]);

  const onSubmit = handleSubmit(async (data) => {
    setFormError(null);

    if (data.samePolicyAddress && isHistorical) {
      data.loss.address = defaultValues.policyHolder.address;
    } else if (data.samePolicyAddress) {
      delete data.loss.address;
    }
    delete data.samePolicyAddress;

    await nextPage(merge({}, pendingChanges, data));
    if (isHistorical) {
      analytics.track(EVENTS.LOSS_DETAILS_UPDATED, { ...fnol, ...data });
    } else {
      analytics.track(EVENTS.CLAIM_ASSOCIATED, { ...fnol, ...data });
    }
  });

  useEffect(() => {
    if (
      (typeOfLoss && !defaultValues?.causeOfLoss) ||
      typeOfLoss !== defaultValues?.typeOfLoss ||
      (typeOfLoss === defaultValues?.typeOfLoss &&
        causeOfLoss !== defaultValues?.causeOfLoss)
    ) {
      setValue('causeOfLoss', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfLoss, setValue]);

  //Manual address default state = FL
  const setState = () => {
    setValue('loss.address.state', 'FL');
  };

  if (watch('loss.address.manual')) {
    setTimeout(setState, 5);
  }
  //

  return (
    <Page
      stage="Tell Us About Your Incident"
      subtitle="Please provide us with more details about the incident and where it occurred. Select a type and cause that best describes the incident."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Form.Col>
              <Select
                label="Damage Type"
                error={errors?.typeOfLoss?.message}
                options={lossMapping?.types}
                {...register('typeOfLoss', { required: 'Required' })}
              />
            </Form.Col>
            <Form.Col>
              <Select
                label="Loss Cause"
                error={errors?.causeOfLoss?.message}
                options={typeOfLoss && lossMapping?.causes[typeOfLoss]}
                disabled={!typeOfLoss}
                {...register('causeOfLoss', { required: 'Required' })}
              />
            </Form.Col>
          </Form.Row>
          <Select
            label="Loss Location"
            data-testid="lossLocation"
            options={lossLocations}
            {...register('loss.location', { required: 'Required' })}
            error={errors?.loss?.location?.message}
          />
          {otherLocation && (
            <AutocompleteAddress
              name="loss.address"
              isHistorical={isHistorical && !defaultSamePolicyAddress}
              {...form}
            />
          )}
          <Form.Row>
            <Form.Col>
              <RadioInput
                label="Catastrophe"
                labelPosition="above"
                defaultValue={defaultValues.catastrophe}
                control={control}
                rules={{ required: 'Required' }}
                name="catastrophe"
                error={errors?.catastrophe?.message}
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>
              <RadioInput
                label="Do you have a written estimate?"
                labelPosition="above"
                defaultValue={defaultValues.writtenEstimate}
                control={control}
                rules={{ required: 'Required' }}
                name="writtenEstimate"
                error={errors?.writtenEstimate?.message}
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>
              <RadioInput
                label="Have you hired a contractor in relation to this claim?"
                labelPosition="above"
                defaultValue={defaultValues.hiredContractor}
                control={control}
                rules={{ required: 'Required' }}
                name="hiredContractor"
                error={errors?.hiredContractor?.message}
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            </Form.Col>
          </Form.Row>
          <TextArea
            label="Description of Incident"
            maxLength={400}
            minRows={4}
            {...register('loss.description', {
              required: 'Required',
            })}
            error={errors?.loss?.description?.message}
          />
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="violet"
          onClick={onSubmit}
          type="submit"
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

LossDetails.displayName = 'LossDetails';

export default LossDetails;
