import React, { useLayoutEffect } from 'react';
import { Loading } from 'src/common';
import { scrollToTop } from 'src/lib/util';
import analytics from 'src/lib/analytics';
import { useModel } from 'src/lib/hooks';

const withPages = (pages = []) => {
  const getPageName = (index) => {
    if ((!index && index !== 0) || index < 0 || index >= pages.length) {
      return null;
    }

    return pages[index]?.displayName;
  };

  const PageController = ({ initialSkipList = [] }) => {
    const {
      page,
      pageIndex,
      furthestReached,
      furthestIndex,
      skipList: managedSkipList,
    } = useModel.session();
    const user = useModel.user();
    const { update } = useModel.fnol.dispatch();
    const { setSession, updatePage } = useModel.session.dispatch();

    const routerInitialized = !!page;
    const skipList = routerInitialized ? managedSkipList : initialSkipList;

    const isSkipped = (index) => {
      const name = getPageName(index);
      return !name || (skipList.length && skipList.includes(name));
    };

    useLayoutEffect(() => {
      scrollToTop();
    }, [page]);

    useLayoutEffect(() => {
      if (!pages.length) {
        throw new Error('Page list was empty.');
      }

      const session = {};
      if (page) {
        session.pageIndex = pages.findIndex((p) => p.displayName === page);
      } else if (pageIndex === 0) {
        session.pageIndex = 0;
        while (
          isSkipped(session.pageIndex) &&
          session.pageIndex < pages.length - 1
        ) {
          session.pageIndex += 1;
        }
        session.page = getPageName(session.pageIndex);
      }

      if (furthestReached) {
        session.furthestIndex = pages.findIndex(
          (p) => p.displayName === furthestReached
        );
      }

      if (!routerInitialized) {
        session.skipList = initialSkipList;
      }
      analytics.page(getPageName(session.pageIndex));

      setSession(session);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isHistorical = pageIndex < furthestIndex;

    if (!routerInitialized) {
      return <Loading />;
    }

    let nextPage = () => {};
    let nextIndex = pageIndex + 1;
    while (isSkipped(nextIndex) && nextIndex < pages.length - 1) {
      nextIndex += 1;
    }
    if (!isSkipped(nextIndex)) {
      const nextName = getPageName(nextIndex);
      nextPage = async (
        updates,
        opts = {
          skipUpdate: false,
          waitForUpdate: true,
        }
      ) => {
        if (user?.hasSession && !opts.skipUpdate) {
          await update({
            updates,
            ...opts,
            page: nextName,
            pageIndex: nextIndex,
          });
        } else {
          await updatePage({
            ...opts,
            page: nextName,
            pageIndex: nextIndex,
          });
        }
        analytics.page(nextName);
      };
      nextPage.pageName = nextName;
    }

    let prevPage = null;
    let prevIndex = pageIndex - 1;
    while (isSkipped(prevIndex) && prevIndex > 0) {
      prevIndex -= 1;
    }
    if (!isSkipped(prevIndex)) {
      const prevName = getPageName(prevIndex);
      prevPage = async () => {
        if (user?.hasSession) {
          await update({
            page: prevName,
            pageIndex: prevIndex,
            waitForUpdate: false,
          });
        } else {
          await updatePage({
            page: prevName,
            pageIndex: prevIndex,
          });
        }
        analytics.page(prevName);
      };
      prevPage.pageName = prevName;
    }

    const PageContent = pages[pageIndex];
    return (
      <PageContent
        nextPage={nextPage}
        isHistorical={isHistorical}
        prevPage={prevPage}
      />
    );
  };

  return PageController;
};

export default withPages;
