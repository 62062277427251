import React from 'react';
import Button from '../Button';
import Modal from '../Modal';

import styles from './InactivityModal.module.css';

const InactivityModal = ({ modalVisible, onContinue }) => {
  return (
    modalVisible && (
      <Modal ariaLabel="Are you still there?" showModal={modalVisible}>
        <h2>Are you still there?</h2>
        <p className={styles.paragraph}>
          You are about to be logged out due to inactivity.
          <br /> Click the button to continue your session.
        </p>
        <div className={styles.buttonsModal}>
          <Button
            onClick={onContinue}
            aria-label="Continue"
            color="violet"
            variant="small"
            id="continue-session-button"
            className={styles.button}
          >
            Continue
          </Button>
        </div>
      </Modal>
    )
  );
};

export default InactivityModal;
