import React, { useLayoutEffect, useState } from 'react';
import { Page, Error, Button, Loading } from 'src/common';

import { useAuth0 } from 'src/lib/hooks';

const Login = () => {
  const { completeLogin, logout } = useAuth0();
  const [error, setError] = useState(null);

  useLayoutEffect(() => {
    const login = async () => {
      try {
        await completeLogin();
      } catch (err) {
        setError('Failed to authenticate you.');
      }
    };
    login();
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => logout('/');

  return error ? (
    <Page>
      <>
        <Page.Section>
          <Error>{error}</Error>
        </Page.Section>
        <Page.Buttons>
          <Button color="violet" type="submit" onClick={handleLogout}>
            Start Over
          </Button>
        </Page.Buttons>
      </>
    </Page>
  ) : (
    <Loading />
  );
};

export default Login;
