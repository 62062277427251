const states = [
  /*{ id: 'AL', title: 'Alabama' },
  { id: 'AK', title: 'Alaska' },
  { id: 'AS', title: 'American Samoa' },
  { id: 'AZ', title: 'Arizona' },
  { id: 'AR', title: 'Arkansas' },
  { id: 'CA', title: 'California' },
  { id: 'CO', title: 'Colorado' },
  { id: 'CT', title: 'Connecticut' },
  { id: 'DE', title: 'Delaware' },
  { id: 'DC', title: 'District of Columbia' },*/
  { id: 'FL', title: 'Florida' },
  /*{ id: 'GA', title: 'Georgia' },
  { id: 'GU', title: 'Guam' },
  { id: 'HI', title: 'Hawaii' },
  { id: 'ID', title: 'Idaho' },
  { id: 'IL', title: 'Illinois' },
  { id: 'IN', title: 'Indiana' },
  { id: 'IA', title: 'Iowa' },
  { id: 'KS', title: 'Kansas' },
  { id: 'KY', title: 'Kentucky' },
  { id: 'LA', title: 'Louisiana' },
  { id: 'MH', title: 'Marshall Islands' },
  { id: 'MA', title: 'Massachusetts' },
  { id: 'MI', title: 'Michigan' },
  { id: 'FM', title: 'Micronesia, Federated States Of' },
  { id: 'MN', title: 'Minnesota' },
  { id: 'MS', title: 'Mississippi' },
  { id: 'MO', title: 'Missouri' },
  { id: 'MT', title: 'Montana' },
  { id: 'NE', title: 'Nebraska' },
  { id: 'NV', title: 'Nevada' },
  { id: 'NH', title: 'New Hampshire' },
  { id: 'NJ', title: 'New Jersey' },
  { id: 'NM', title: 'New Mexico' },
  { id: 'NY', title: 'New York' },
  { id: 'NC', title: 'North Carolina' },
  { id: 'ND', title: 'North Dakota' },
  { id: 'MP', title: 'Northern Mariana Islands' },
  { id: 'OH', title: 'Ohio' },
  { id: 'OK', title: 'Oklahoma' },
  { id: 'OR', title: 'Oregon' },
  { id: 'PW', title: 'Palau' },
  { id: 'PA', title: 'Pennsylvania' },
  { id: 'PR', title: 'Puerto Rico' },
  { id: 'RI', title: 'Rhode Island' },
  { id: 'SC', title: 'South Carolina' },
  { id: 'SD', title: 'South Dakota' },
  { id: 'TN', title: 'Tennessee' },
  { id: 'TX', title: 'Texas' },
  { id: 'UT', title: 'Utah' },
  { id: 'VT', title: 'Vermont' },
  { id: 'VI', title: 'Virgin Islands, U.S.' },
  { id: 'VA', title: 'Virginia' },
  { id: 'WA', title: 'Washington' },
  { id: 'WV', title: 'West Virginia' },
  { id: 'WI', title: 'Wisconsin' },
  { id: 'WY', title: 'Wyoming' },*/
];

export const guidanceStates = [{ id: 'FL', title: 'Florida' }];

export default states;
