import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Form, Modal, Page, Button, Error } from 'src/common';
import { useForm, useModel, useAuth0 } from 'src/lib/hooks';
import analytics, { EVENTS } from 'src/lib/analytics';

import styles from './Cancel.module.css';

const Cancel = () => {
  const { logout } = useAuth0();
  const history = useHistory();
  const fnol = useModel.fnol();
  const { cancel } = useModel.fnol.dispatch();
  const {
    register,
    handleSubmit,
    watch,
    formError,
    formState: { errors, isSubmitting },
  } = useForm();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    analytics.page('Cancel');
  }, []);

  const confirmedCancellation = watch('confirmedCancellation');

  const toggleModal = () => setOpenModal((open) => !open);

  const onCancelSubmission = handleSubmit(async () => {
    await cancel();
    analytics.track(EVENTS.CANCELLED_CLAIM, fnol);
    toggleModal();
  });

  const onAccept = handleSubmit(async () => {
    toggleModal();
    await logout();
  });

  const onGoBack = () => {
    history.replace('/submit');
  };

  return (
    <Page>
      <Page.Section title="Cancel Online Claim Submission?">
        {formError && <Error>{formError}</Error>}
        <p className={styles.paragraph}>
          You are about to cancel your online claim submission. If you are sure
          you want to cancel, please confirm by selecting the box below and then
          clicking "Cancel My Submission".
        </p>
        <Form onSubmit={onCancelSubmission} className={styles.form}>
          <Checkbox
            label="Cancel my submission"
            errors={errors?.confirmedCancellation?.message}
            {...register('confirmedCancellation', { required: 'Required' })}
          />
        </Form>
        <p className={styles.paragraph}>
          If you wish to get in touch with us regarding your reasons for
          cancelling your claim submission, please call us at the number below.
        </p>
        <p className={styles.paragraph}>Phone: 866-230-3758</p>
      </Page.Section>
      <Page.Buttons>
        <Button color="light" onClick={onGoBack} disabled={isSubmitting}>
          Go Back
        </Button>
        <Button
          color="violet"
          onClick={onCancelSubmission}
          loading={isSubmitting}
          disabled={!confirmedCancellation}
        >
          Cancel My Submission
        </Button>
      </Page.Buttons>
      {openModal && (
        <Modal
          ariaLabel="Claim submission cancelled"
          showModal={openModal}
          enableCloseButton
          onClose={onAccept}
        >
          <div className={styles.modalContent}>
            <h2>Claim submission cancelled</h2>
            <p className={styles.paragraph}>
              Your online claim submission has been cancelled. Remember that you
              can still get in touch with us at any time if you have any
              questions.
            </p>

            <p className={styles.paragraph}>Phone: 866-230-3758</p>
            <div className={styles.modalButton}>
              <Button color="violet" onClick={onAccept} type="button">
                Ok
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </Page>
  );
};

export default Cancel;
