import React from 'react';
import { Route, Switch } from 'react-router';
import { Passwordless } from 'src/flows';
import { Cancel, Login, Resume, MaintenanceMode } from 'src/pages';
import { InitialDisclaimer } from './pages';
import config from './config';

const App = () => {
  if (config.maintenanceMode) {
    return <MaintenanceMode />;
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/resume" component={Resume} />
      <Route exact path="/cancel" component={Cancel} />
      <Route path="/submit" component={Passwordless} />
      <Route path="/" component={InitialDisclaimer} />
    </Switch>
  );
};

export default App;
