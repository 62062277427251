import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Select } from 'src/common';
import Modal from 'src/common/Modal';
import { guidanceStates } from 'src/lib/states';
import { guidanceImagesStates } from 'src/lib/constants';

import styles from './Guidance.module.css';

const Guidance = ({ visible, defaultState, toggle }) => {
  const [guidanceImages, setGuidanceImages] = useState([]);
  const { register, watch, setValue } = useForm();
  const state = watch('state');

  useEffect(() => {
    if (defaultState) {
      setValue('state', defaultState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      const images = guidanceImagesStates[state]?.images ?? [];
      setGuidanceImages(images);
    }
  }, [state]);

  return (
    visible && (
      <Modal
        ariaLabel="Policy Number Location"
        showModal={visible}
        enableCloseButton
        onClose={toggle}
      >
        <div className={styles.content}>
          <h2>Policy Number Location</h2>
          <p className={styles.paragraph}>
            Please select a state to view example images to help you locate your
            Policy Number on your Declaration page.
          </p>

          <Select
            label="State"
            name="state"
            options={guidanceStates}
            {...register(`state`)}
          />
          {guidanceImages?.map((i) => (
            <img
              alt="Slide insurance product policy with policy number highlighted"
              key={i.name}
              src={i.path}
            />
          ))}
        </div>
      </Modal>
    )
  );
};
export default Guidance;
