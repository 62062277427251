import React, { useEffect, useMemo, useState } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'tailwindcss-classnames';

import styles from './AptInput.module.css';

import debugData from 'src/lib/debugData';
import { DropdownMenu } from 'src/common';

const AptInput = ({
  name,
  label,
  placeholder,
  disabled,
  onChange,
  aptList,
  error,
  column,
  value,
}) => {
  const [search, setSearch] = useState('');

  const result = useMemo(
    () =>
      aptList.filter((apt) => apt.toLowerCase().includes(search.toLowerCase())),
    [aptList, search]
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu,
  } = useCombobox({
    items: result || [],
    onInputValueChange: (change) => {
      const { inputValue } = change;
      setSearch(inputValue);
      onChange(inputValue);
    },
    onSelectedItemChange: (change) => {
      onChange(change.selectedItem);
    },
  });

  const handleBlur = ({ target: { value } }) => {
    const index = result.findIndex(
      (option) => option.toLowerCase() === value.toLowerCase()
    );
    if (index > -1) {
      selectItem(result[index]);
    }
  };

  useEffect(() => {
    if (result && result.length === 1) {
      selectItem(result[0]);
      closeMenu();
    }
  }, [search, result, selectItem, closeMenu]);

  return (
    <div className={classNames(styles.container, !column && styles.row)}>
      {label && (
        <label className={styles.label} {...getLabelProps()}>
          {label}
        </label>
      )}
      <div {...getComboboxProps()}>
        <input
          type="text"
          className={classNames(styles.input, error && styles.error)}
          {...getInputProps({
            name,
            disabled,
            placeholder,
            onBlur: handleBlur,
          })}
          value={value}
          {...debugData({ input: 'apartment' })}
          aria-invalid={error ? 'true' : 'false'}
          {...(error && { 'aria-describedby': `${name}-error` })}
        />
        <DropdownMenu
          isOpen={isOpen}
          disabled={disabled}
          options={result}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
        />
      </div>
      <div className={styles.errorContainer}>
        {error && (
          <span id={`${name}-error`} className={styles.textError}>
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

export default AptInput;
