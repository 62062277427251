import React, { useEffect } from 'react';
import { useAuth0, useForm, useModel } from 'src/lib/hooks';

import { Button, Error, Form, Input, MaskedInput, Page } from 'src/common';
import { validateEmail } from 'src/lib/validations';
import EmailVerification from '../EmailVerification';
import { contactFields } from 'src/lib/constants';

const AboutYourself = ({ prevPage, nextPage }) => {
  const { stageChanges, skip } = useModel.session.dispatch();
  const { pendingChanges } = useModel.session();
  const { startLogin, handleStartLoginError, user } = useAuth0();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    formError,
  } = useForm({
    defaultValues: pendingChanges,
    onError: async ({ err, setFormError, defaultOnError }) => {
      if (err.statusCode) {
        setFormError(handleStartLoginError(err));
      } else {
        await defaultOnError();
      }
    },
  });

  const contactEmail = watch('loss.reportedBy.email');
  const contactByRole =
    contactFields[pendingChanges?.loss?.reportedBy?.participantRole];

  useEffect(() => {
    skip({
      [EmailVerification.Representative.displayName]:
        user?.email === contactEmail,
    });
  }, [skip, user?.email, contactEmail]);

  const onSubmit = handleSubmit(async (data) => {
    if (
      !user?.hasSession ||
      (user?.hasSession && user?.email !== data.loss.reportedBy.email)
    ) {
      await startLogin(data.loss.reportedBy.email);
    }
    stageChanges(data);
    nextPage();
  });

  return (
    <Page stage="Tell Us About Yourself">
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            {contactByRole?.firstName && (
              <Form.Col>
                <Input
                  label={contactByRole?.firstName}
                  error={errors?.loss?.reportedBy?.firstName?.message}
                  {...register('loss.reportedBy.firstName', {
                    required: 'Required',
                  })}
                />
              </Form.Col>
            )}
            {contactByRole?.lastName && (
              <Form.Col>
                <Input
                  label={contactByRole?.lastName}
                  error={errors?.loss?.reportedBy?.lastName?.message}
                  {...register('loss.reportedBy.lastName', {
                    required: 'Required',
                  })}
                />
              </Form.Col>
            )}
          </Form.Row>
          <Form.Row>
            {contactByRole?.phone && (
              <Form.Col>
                <MaskedInput
                  mask="(999) 999-9999"
                  label={contactByRole?.phone}
                  name="loss.reportedBy.phone"
                  control={control}
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /\(\d{3}\) \d{3}-\d{4}/,
                      message: 'Invalid phone number.',
                    },
                  }}
                  error={errors?.loss?.reportedBy?.phone?.message}
                />
              </Form.Col>
            )}
            {contactByRole?.email && (
              <Form.Col>
                <Input
                  label={contactByRole?.email}
                  error={errors?.loss?.reportedBy?.email?.message}
                  {...register('loss.reportedBy.email', {
                    required: 'Required',
                    validate: validateEmail,
                  })}
                />
              </Form.Col>
            )}
          </Form.Row>
          <Form.Row>
            {contactByRole?.companyName && (
              <Form.Col>
                <Input
                  label={contactByRole?.companyName?.label}
                  error={
                    errors?.loss?.reportedBy?.[contactByRole?.companyName?.key]
                      ?.message
                  }
                  {...register(
                    `loss.reportedBy.${contactByRole?.companyName?.key}`,
                    {
                      required: 'Required',
                    }
                  )}
                />
              </Form.Col>
            )}
            {contactByRole?.accountNumber && (
              <Form.Col>
                <Input
                  label={contactByRole?.accountNumber?.label}
                  error={
                    errors?.loss?.reportedBy?.[
                      contactByRole?.accountNumber?.key
                    ]?.message
                  }
                  {...register(
                    `loss.reportedBy.${contactByRole?.accountNumber?.key}`,
                    {
                      required: 'Required',
                    }
                  )}
                />
              </Form.Col>
            )}
          </Form.Row>
          <Form.Row>
            {contactByRole?.clientFirstName && (
              <Form.Col>
                <Input
                  label={contactByRole?.clientFirstName?.label}
                  error={
                    errors?.loss?.reportedBy?.[
                      contactByRole?.clientFirstName?.key
                    ]?.message
                  }
                  {...register(
                    `loss.reportedBy.${contactByRole?.clientFirstName?.key}`,
                    {
                      required: 'Required',
                    }
                  )}
                />
              </Form.Col>
            )}
            {contactByRole?.clientLastName && (
              <Form.Col>
                <Input
                  label={contactByRole?.clientLastName?.label}
                  error={
                    errors?.loss?.reportedBy?.[
                      contactByRole?.clientLastName?.key
                    ]?.message
                  }
                  {...register(
                    `loss.reportedBy.${contactByRole?.clientLastName?.key}`,
                    {
                      required: 'Required',
                    }
                  )}
                />
              </Form.Col>
            )}
          </Form.Row>
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          type="button"
          color="light"
          onClick={prevPage}
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          type="submit"
          color="violet"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

AboutYourself.displayName = 'AboutYourself';

export default AboutYourself;
