import React from 'react';
import { Button, FileInput, Form, Page, Error } from 'src/common';
import { acceptedFileTypes } from 'src/lib/constants';
import config from 'src/config';
import { validateFiles } from 'src/lib/validations';

import styles from './UploadMedia.module.css';
import { useModel, useForm, useErrorHandling } from 'src/lib/hooks';

const supportedFileExtension = acceptedFileTypes.map((f) => f.ext);

const getUnitSize = (size) =>
  size >= 1024
    ? `${parseFloat((size / Math.floor(1024)).toFixed(2))}GB`
    : `${size}MB`;

const UploadMedia = ({ prevPage, nextPage }) => {
  const { uploadAttachment, getAttachmentsUrl, deleteAttachment } =
    useModel.fnol.dispatch();
  const defaultAttachments = useModel.fnol.defaults();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    formError,
    setFormError,
    watch,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultAttachments, mode: 'onChange' });
  const handleError = useErrorHandling();
  const onSubmit = handleSubmit(async (data) => {
    data.attachments.forEach((_, idx) =>
      setValue(`attachments[${idx}].error`, false)
    );
    setFormError(null);
    try {
      const attachmentsWithUrl = await getAttachmentsUrl(data.attachments);

      let failedUpload = false;
      for (let attachment of attachmentsWithUrl) {
        const idx = data.attachments.findIndex(
          (a) => a.fileName === attachment.fileName
        );
        try {
          setValue(`attachments[${idx}].uploading`, true);
          await uploadAttachment(attachment);
          setValue(`attachments[${idx}].uploading`, false);
          setValue(`attachments[${idx}].uploaded`, true);
        } catch (err) {
          failedUpload = true;
          setValue(`attachments[${idx}].uploading`, false);
          setValue(`attachments[${idx}].error`, true);
          setValue(`attachments[${idx}].uploaded`, false);

          setFormError(
            'Some files failed to upload. Please click "Next Step" to retry the failed uploads.'
          );
        }
      }

      if (!failedUpload) {
        await nextPage();
      }
    } catch (err) {
      setFormError(await handleError(err));
    }
  });

  const onDelete = async (file, idx) => {
    try {
      setFormError(null);
      setValue(`attachments[${idx}].error`, false);
      setValue(`attachments[${idx}].uploading`, true);
      await deleteAttachment(file);
      setValue(`attachments[${idx}].uploading`, false);
    } catch (err) {
      setValue(`attachments[${idx}].uploading`, false);
      setValue(`attachments[${idx}].error`, true);
      setFormError(await handleError(err));
      throw err;
    }
  };

  return (
    <Page
      stage="Photos, Videos, and Documents"
      subtitle="Please upload photos, videos and documents that will assist in the handling of your claim."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <div className={styles.content}>
            <p>
              {`Click on the drag and drop box to capture new files from your
              device or to upload existing files. You can upload up to ${
                config.files.maxNumber
              } 
              files. Videos can be up to ${getUnitSize(
                config.files.videoSize
              )}. 
              Any other file can be up to ${getUnitSize(
                config.files.otherSize
              )}.`}
            </p>
            <p>
              Disclaimer: Photos and videos may contain location information.
            </p>
            <p>{`Supported files types: 
            ${supportedFileExtension.join(', ')}.`}</p>
          </div>
          <FileInput
            aria-label="Upload files"
            name="attachments"
            control={control}
            error={errors?.attachments?.message}
            rules={{
              validate: validateFiles,
            }}
            disabled={isSubmitting}
            getValues={getValues}
            watch={watch}
            onDelete={onDelete}
          />
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="light"
          onClick={prevPage}
          type="button"
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="violet"
          onClick={onSubmit}
          loading={isSubmitting}
          type="button"
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

UploadMedia.displayName = 'UploadMedia';

export default UploadMedia;
