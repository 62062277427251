import React from 'react';
import { Page } from 'src/common';
import style from './MaintenanceMode.module.css';

const MaintenanceMode = () => (
  <Page title="We're Undergoing Maintenance">
    <Page.Section>
      <p className={style.textCenter}>
        We are currently undergoing scheduled maintenance. Please try again
        later or call us at (866) 230-3758 for assistance.
      </p>
    </Page.Section>
  </Page>
);

export default MaintenanceMode;
