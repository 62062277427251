import EmailVerification from './EmailVerification';
import withSession from 'src/lib/hoc/withSession';

EmailVerification.Representative = withSession(
  EmailVerification.Representative,
  { secured: false }
);
EmailVerification.PolicyHolder = withSession(EmailVerification.PolicyHolder, {
  secured: false,
});

export default EmailVerification;
