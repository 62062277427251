import React, { useCallback, useState } from 'react';
import { Button, Error, Form, InfoBox, MaskedInput, Page } from 'src/common';
import { useAuth0, useForm } from 'src/lib/hooks';
import styles from './Common.module.css';

const Common = ({ onBack, backTitle }) => {
  const {
    user,
    verifyCode,
    handleVerifyCodeError,
    startLogin,
    handleStartLoginError,
  } = useAuth0();
  const [sending, setSending] = useState(false);
  const [codeResent, setcodeResent] = useState(false);
  const { handleSubmit, errors, formState, formError, control, setFormError } =
    useForm({
      onError: async ({ err, setFormError, defaultOnError }) => {
        if (err?.error) {
          setFormError(handleVerifyCodeError(err));
        } else {
          await defaultOnError();
        }
      },
    });

  const onSubmit = handleSubmit(async (data) => {
    setcodeResent(false);
    await verifyCode(data.verificationCode.trim());
  });

  const resendCode = useCallback(async () => {
    setFormError(null);
    setcodeResent(true);
    setSending(true);
    try {
      await startLogin(user.email);
    } catch (err) {
      setFormError(handleStartLoginError(err));
    } finally {
      setSending(false);
    }
  }, [user?.email, startLogin, handleStartLoginError, setFormError]);
  return (
    <>
      <Page.Section>
        {codeResent && (
          <InfoBox className={styles.infoBox}>
            <p role="alert">
              A new verification code was sent, please check your email inbox.
            </p>
          </InfoBox>
        )}
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <p className={styles.message}>
            Please enter the verification code we just sent to{' '}
            <b>{user?.email}</b>.
          </p>
          <MaskedInput
            label="Verification code"
            name="verificationCode"
            control={control}
            validLength={6}
            rules={{
              required: 'Required',
            }}
            mask="999999"
            error={errors?.verificationCode?.message}
          />
          <div className={styles.resendMessage}>
            Didn't receive the code?
            <button
              className={styles.link}
              type="button"
              onClick={resendCode}
              disabled={formState.isSubmitting || sending}
            >
              {sending ? 'Sending...' : 'Resend'}
            </button>
          </div>
        </Form>
      </Page.Section>
      <Page.Buttons>
        {onBack && (
          <Button
            color="light"
            disabled={formState.isSubmitting}
            onClick={onBack}
            type="button"
          >
            {backTitle || 'Go Back'}
          </Button>
        )}
        <Button
          color="violet"
          onClick={onSubmit}
          type="submit"
          loading={formState.isSubmitting}
        >
          Continue
        </Button>
      </Page.Buttons>
    </>
  );
};

export default Common;
