import React from 'react';
import classNames from 'tailwindcss-classnames';

import { acceptedFileTypes, unsupportedFile } from 'src/lib/constants';
import { Button, Icon, Spinner, ToolTip } from 'src/common';
import styles from './FilePreview.module.css';

const isFileWithError = (file) => {
  return (
    !file.isSupported ||
    file.isTooBig ||
    !!file.error ||
    file.haveInvalidFileName
  );
};

const FilePreview = ({ files, enableDelete = false, onDeleteFile }) => {
  const renderContent = (file) => {
    if (file.uploading) {
      return <Spinner color="violet" size="42" className={styles.icon} />;
    }
    if (file.uploaded) {
      return (
        <Icon
          name="checkCircleFull"
          size="42"
          className={styles.icon}
          title="file uploaded"
          role="img"
        />
      );
    }
    return (
      <Icon
        name={file.icon}
        size="42"
        color={
          file.isTooBig || file.error || file.haveInvalidFileName
            ? 'error'
            : 'violet'
        }
        className={styles.icon}
      />
    );
  };

  const getFileIcon = (file) => {
    const ext = file.fileName?.split('.')?.pop();
    const icon = acceptedFileTypes.find(
      (s) => s.ext === ext?.toLowerCase()
    )?.icon;
    return file.isSupported ? icon : unsupportedFile.icon;
  };

  return (
    <div className={styles.container}>
      {files &&
        files.map((file, idx) => {
          file.isSupported = file.isSupported !== false;
          file.icon = getFileIcon(file);
          return (
            <div className={styles.preview} key={file.fileName}>
              {file.uploading !== true && enableDelete && (
                <Button
                  aria-label={`Delete ${file.fileName}`}
                  variant="small"
                  color="transparent"
                  type="button"
                  onClick={() => onDeleteFile(file, idx)}
                  className={styles.delete}
                >
                  <Icon
                    name="times"
                    size="12"
                    color={isFileWithError(file) && 'error'}
                  />
                </Button>
              )}
              <ToolTip content={file.fileName}>
                <div
                  key={file.fileName}
                  className={classNames(styles.imageContainer, {
                    [styles['unsupportedImageContainer']]:
                      isFileWithError(file),
                    [styles['icon']]: file.icon,
                  })}
                >
                  <div className={styles.iconContainer} role="status">
                    {renderContent(file)}
                  </div>
                  <div>
                    <div
                      className={classNames(
                        styles.imageDescription,
                        styles.filenameTruncate,
                        {
                          [styles['errorText']]: isFileWithError(file),
                        }
                      )}
                    >
                      {file.fileName}
                    </div>
                  </div>
                </div>
              </ToolTip>
            </div>
          );
        })}
    </div>
  );
};

export default FilePreview;
