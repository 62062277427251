import React from 'react';
import { useAuth0, useForm, useModel } from 'src/lib/hooks';
import { Form, Input, MaskedInput, Error, Button, Page } from 'src/common';
import { validateEmail, validateNotEmpty } from 'src/lib/validations';

const ContactInfo = ({ nextPage }) => {
  const { stageChanges } = useModel.session.dispatch();
  const { pendingChanges } = useModel.session();
  const { startLogin, handleStartLoginError } = useAuth0();

  const {
    register,
    handleSubmit,
    control,
    formError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: pendingChanges,
    onError: async ({ err, setFormError, defaultOnError }) => {
      if (err.statusCode) {
        setFormError(handleStartLoginError(err));
      } else {
        await defaultOnError();
      }
    },
  });
  const onSubmit = handleSubmit(async (data) => {
    await startLogin(data.loss.reportedBy.email);
    data.loss.reportedBy.participantRole = 'Policy Holder';

    stageChanges(data);
    await nextPage(null, { skipUpdate: true });
  });

  return (
    <Page
      stage="Contact Info"
      subtitle="Please provide the best information for us to contact you. We will verify the email you provide and use it to send notifications during the claim submission process."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Form.Col>
              <Input
                label="First Name"
                {...register('loss.reportedBy.firstName', {
                  required: 'Required',
                  validate: validateNotEmpty,
                })}
                error={errors?.loss?.reportedBy?.firstName?.message}
              />
            </Form.Col>
            <Form.Col>
              <Input
                label="Last Name"
                {...register('loss.reportedBy.lastName', {
                  required: 'Required',
                  validate: validateNotEmpty,
                })}
                error={errors?.loss?.reportedBy?.lastName?.message}
              />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>
              <MaskedInput
                mask="(999) 999-9999"
                control={control}
                label="Phone"
                name="loss.reportedBy.phone"
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /\(\d{3}\) \d{3}-\d{4}/,
                    message: 'Invalid phone number',
                  },
                }}
                error={errors?.loss?.reportedBy?.phone?.message}
              />
            </Form.Col>
            <Form.Col>
              <Input
                label="Email"
                {...register('loss.reportedBy.email', {
                  required: 'Required',
                  validate: validateEmail,
                  message: 'Invalid email',
                })}
                error={errors?.loss?.reportedBy?.email?.message}
                info="If you do not have a valid and accessible email address, please call (866) 230-3758 to report your claim"
              />
            </Form.Col>
          </Form.Row>
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button color="violet" onClick={onSubmit} loading={isSubmitting}>
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

ContactInfo.displayName = 'ContactInfo';

export default ContactInfo;
