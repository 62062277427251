import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Icon } from 'src/common';
import FocusTrap from 'focus-trap-react';

import styles from './Modal.module.css';

const Modal = ({
  ariaLabel = 'modal',
  actions,
  children,
  disableCloseOnEsc = false,
  enableCloseButton = false,
  heading = '',
  onClose,
  showModal = false,
}) => {
  const [appElement] = useState(() => document.getElementById('root'));

  return (
    <ReactModal
      appElement={appElement}
      role="dialog"
      isOpen={showModal}
      aria-modal="true"
      onRequestClose={onClose}
      contentLabel={ariaLabel}
      shouldCloseOnEsc={!disableCloseOnEsc}
      shouldCloseOnOverlayClick={!disableCloseOnEsc}
      className={styles.container}
      overlayClassName={styles.overlay}
    >
      <FocusTrap>
        <div className={styles.modal}>
          <div className={styles.heading}>
            <div>{heading}</div>
            <div>
              {enableCloseButton && (
                <Button
                  variant="small"
                  color="transparent"
                  onClick={onClose}
                  className={styles.modalButton}
                  aria-label="Close"
                >
                  <Icon name="times" size="12" />
                </Button>
              )}
            </div>
          </div>
          {children && <div className={styles.children}>{children}</div>}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      </FocusTrap>
    </ReactModal>
  );
};
export default Modal;
