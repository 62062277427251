import React from 'react';
import classnames from 'tailwindcss-classnames';

import styles from './InfoBox.module.css';

const InfoBox = ({ className, children }) => (
  <div className={classnames(styles.infoBox, className)}>{children}</div>
);

export default InfoBox;
