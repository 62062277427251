import React from 'react';

import styles from './Details.module.css';

const Details = ({ name, children }) => (
  <dl className={styles.list}>
    {React.Children.map(
      children,
      (child, i) =>
        child &&
        React.cloneElement(child, {
          name,
          id: name + i,
        })
    )}
  </dl>
);

const Item = ({ label, value }) => (
  <div className={styles.itemContainer}>
    <dt className={styles.label}>{label}</dt>
    <dd>{value}</dd>
  </div>
);

const Row = ({ children }) => <div className={styles.row}>{children}</div>;

Details.Item = Item;
Details.Row = Row;
export default Details;
