import React from 'react';
import styles from './Header.module.css';
import { slideLogo } from 'src/assets/img';
import NavActions from './NavActions';
import config from 'src/config';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <img
          src={slideLogo}
          alt="Slide Insurance logo"
          id="logo"
          className={styles.logo}
        />
      </div>
      {!config.maintenanceMode && <NavActions />}
    </header>
  );
};

export default Header;
