import React from 'react';
import styles from './ToolTip.module.css';
const ToolTip = ({ children, delay, content }) => {
  let timeout;

  const [active, setActive] = React.useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  return (
    <div
      className={styles.TooltipWrapper}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && <div className={styles.Tooltip}>{content}</div>}
    </div>
  );
};
export default ToolTip;
