import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import styles from './RadioInput.module.css';

const validValuesValidate = (children, value) => {
  const values = children.map((child) => child.props.value);
  return values.includes(value) || 'Required';
};

const RadioInput = ({
  name,
  label,
  control,
  defaultValue,
  error,
  rules,
  ariaControls,
  disabled = false,
  children,
  labelPosition = 'left',
}) => {
  // radio uses 'false' as a value, use validate instead required
  if (rules?.required) {
    let customError = rules.required.message ?? rules.required;
    let customValidate = rules.validate;

    rules.validate = (v) => {
      if (v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        ...rules,
        validate: {
          checkValidValues: (value) => validValuesValidate(children, value),
        },
      }}
      render={({ field: { onChange, value, ref } }) => (
        <fieldset aria-describedby={`${name}-error`}>
          <div
            className={classNames(styles.container, {
              [styles.left]: labelPosition === 'left',
              [styles.above]: labelPosition === 'above',
              [styles.center]: labelPosition === 'center',
            })}
          >
            <legend
              className={labelPosition === 'center' ? styles.labelCenter : ''}
            >
              {label}
            </legend>
            <div
              className={classNames(styles.optionsList, {
                [styles.together]: ['left', 'center'].includes(labelPosition),
                [styles.start]: labelPosition === 'above',
              })}
            >
              {React.Children.map(
                children,
                (child, i) =>
                  child &&
                  React.cloneElement(child, {
                    name,
                    id: name + i,
                    ariaControls,
                    onChange,
                    error,
                    disabled,
                    selectedValue: value,
                    inputRef: i === 0 && ref,
                  })
              )}
            </div>
          </div>
          <div id={`${name}-error`} className={styles.errorContainer}>
            {error && <span className={styles.errorText}>{error}</span>}
          </div>
        </fieldset>
      )}
    />
  );
};

const Option = ({
  label,
  value,
  disabled,
  name,
  id,
  ariaControls,
  onChange,
  error,
  selectedValue,
  inputRef,
}) => {
  useEffect(() => {
    if (disabled && value === selectedValue) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const isChecked = value === selectedValue;
  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.optionContainer,
        error && styles.errorLabelText
      )}
    >
      <input
        id={id}
        name={name}
        disabled={disabled}
        onChange={() => onChange(value)}
        type="radio"
        value={value}
        defaultChecked={isChecked}
        aria-controls={ariaControls}
        aria-checked={isChecked ? 'true' : 'false'}
        className={classNames(styles.option, {
          [styles.disabled]: disabled,
          [styles.optionError]: error,
        })}
        {...(inputRef && { ref: inputRef })}
      />
      <span className={classNames(styles.label)}>{label}</span>
    </label>
  );
};

RadioInput.Option = Option;

export default RadioInput;
