import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  AutocompleteAddress,
  Button,
  Error,
  Form,
  Icon,
  Input,
  Page,
} from 'src/common';
import { useForm, useModel } from 'src/lib/hooks';
import { timeTransform } from 'src/lib/transforms';
import {
  validateNotFutureDate,
  validatePolicyNumber,
} from 'src/lib/validations';
import Guidance from './Guidance';

import classNames from 'tailwindcss-classnames';
import styles from './PolicyVerification.module.css';

const minDate = '2023-02-01';

const PolicyVerification = ({ nextPage }) => {
  const [showGuidance, setShowGuidance] = useState(false);
  const toggleGuidance = (e) => {
    e.currentTarget.blur();
    setShowGuidance(!showGuidance);
  };
  const todayDate = dayjs().format('YYYY-MM-DD');
  const { verifyPolicy } = useModel.fnol.dispatch();
  const [policyNotFound, setPolicyNotFound] = useState(false);
  const form = useForm();

  const {
    watch,
    register,
    handleSubmit,
    formError,
    formState: { errors, isSubmitting },
  } = form;

  const policyHolderState = watch('policyHolder.address.state');

  const onSubmit = handleSubmit(async (formData) => {
    const data = JSON.parse(JSON.stringify(formData));
    try {
      if (!data.loss.time) {
        delete data.loss['time'];
      }

      delete data?.policyHolder?.address?.apt;

      if (!data.policyHolder.address) {
        data.policyHolder.address = {
          street: '',
          city: '',
          state: '',
          zip: '',
        };
      } else if (!data.policyNumber) {
        delete data.policyNumber;
      }
      let policyNumber = '';

      data.policyHolder.address = {
        ...data.policyHolder.address,
        street: data.policyHolder.address.street.trim() || '',
        city: data.policyHolder.address.city.trim() || '',
      };

      if (data.policyNumber) {
        let regexAgentConnect = /[a-zA-Z]{3}\s?\d{7}/i;
        let regexAgencyLink = /\d{2}\s?\d{2}\s?\d{8}/i;
        let regexAgentConnectFull = /[a-zA-Z]{3}\s?\d{7}\s?\d{2}/i;
        let regexAgencyLinkFull = /\d{2}\s?\d{2}\s?\d{8}\s?\d{2}/i;
        let regexAgentConnectTerm = /[a-zA-Z]{3}\s?\d{7}\s?\d{2}\d{2}/i;
        policyNumber = data.policyNumber.replace(/\s/g, '');

        if (regexAgentConnectTerm.test(policyNumber)) {
          policyNumber =
            policyNumber.substring(0, policyNumber.length - 4) + '%%';
        } else if (
          regexAgentConnectFull.test(policyNumber) ||
          regexAgencyLinkFull.test(policyNumber)
        ) {
          policyNumber =
            policyNumber.substring(0, policyNumber.length - 2) + '%%';
        } else if (
          regexAgentConnect.test(policyNumber) ||
          regexAgencyLink.test(policyNumber)
        ) {
          policyNumber = policyNumber + '%%';
        }
        data.policyNumber = policyNumber;
      }

      await verifyPolicy(data);
      await nextPage();
    } catch (error) {
      if (error?.response?.status === 404) {
        if (!policyNotFound || data.policyNumber) {
          setPolicyNotFound(true);
        }
        throw error;
      } else {
        throw error;
      }
    }
  });

  return (
    <Page
      stage="Policy Verification"
      subtitle="Before we continue, we need to verify you have a valid policy with us. Please provide us with the insured property address and the date of the incident."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Form.Col>
              <Input
                type="date"
                label="Date of Incident"
                {...register('loss.date', {
                  required: 'Required',
                  validate: validateNotFutureDate,
                })}
                max={todayDate}
                min={minDate}
                error={errors?.loss?.date?.message}
              />
            </Form.Col>
            <Form.Col>
              <Input
                type="time"
                label="Incident Time (optional)"
                optional
                {...register('loss.time', {
                  setValueAs: timeTransform.output,
                })}
                error={errors?.loss?.time?.message}
              />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <AutocompleteAddress
              name="policyHolder.address"
              label="Insured Property"
              {...form}
            />
          </Form.Row>
          <Input
            label={
              <div className={styles.buttonContainer}>
                Policy Number
                <Button
                  aria-label="Policy Number Help"
                  onClick={toggleGuidance}
                  color="transparent"
                  type="button"
                  className={styles.button}
                >
                  <Icon
                    name="questionCircle"
                    size={20}
                    className={classNames(styles.icon)}
                  />
                </Button>
              </div>
            }
            {...register('policyNumber', {
              validate: (value) => !value || validatePolicyNumber(value),
              required: 'Required',
            })}
            data-testid="policyNumber"
            autoComplete="off"
            error={errors?.policyNumber?.message}
          />
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button color="violet" onClick={onSubmit} loading={isSubmitting}>
          Next Step
        </Button>
      </Page.Buttons>
      {showGuidance && (
        <Guidance
          defaultState={policyHolderState}
          visible={showGuidance}
          toggle={toggleGuidance}
        />
      )}
    </Page>
  );
};

PolicyVerification.displayName = 'PolicyVerification';

export default PolicyVerification;
