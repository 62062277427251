import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'tailwindcss-classnames';

import styles from './DropdownMenu.module.css';

const offsetModifier = {
  name: 'offset',
  options: {
    offset: ({ placement }) => {
      let yOffset = 0;
      if (placement === 'top') {
        yOffset = 37;
      } else if (placement === 'bottom') {
        yOffset = -8;
      }
      return [0, yOffset];
    },
  },
};
const classModifier = {
  name: 'computeClasses',
  enabled: true,
  phase: 'main',
  fn: ({ state }) => {
    const className =
      state.placement === 'top' ? styles.placementTop : styles.placementBottom;
    state.attributes.popper = { ...state.attributes.popper, className };
    return state;
  },
};
const paddingModifier = {
  name: 'flip',
  options: {
    padding: { bottom: 85 },
  },
};

const DropdownMenu = ({
  isOpen,
  disabled,
  searching,
  error,
  options = [],
  highlightedIndex,
  getMenuProps,
  getItemProps,
  getItemValue,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [offsetModifier, classModifier, paddingModifier],
  });
  useEffect(() => {
    update && update();
  }, [options, update]);

  return (
    <div className={styles.container} ref={setReferenceElement}>
      <div
        ref={setPopperElement}
        style={{
          ...popperStyles.popper,
          left: 0,
          right: 0,
        }}
        {...attributes.popper}
        className={classNames(
          isOpen && attributes.popper.className,
          styles.content,
          {
            [styles['isOpen']]: isOpen,
            [styles['isClose']]: !isOpen,
          },
          error && styles.error
        )}
      >
        <ul
          {...getMenuProps()}
          className={classNames(styles.listContent, 'custom-scrollbar')}
        >
          {isOpen && !disabled && searching && (
            <li className={styles.searching}>Searching...</li>
          )}
          {isOpen && !disabled && !searching && !options.length && (
            <li className={styles.noOptions}>No options</li>
          )}
          {isOpen &&
            !disabled &&
            options.map((item, index) => {
              const itemValue = !!getItemValue ? getItemValue(item) : item;
              return (
                <li
                  key={`${itemValue}${index}`}
                  className={classNames(
                    styles.result,
                    highlightedIndex === index && styles.highlightedResult
                  )}
                  {...getItemProps({ item, index })}
                >
                  {itemValue}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default DropdownMenu;
