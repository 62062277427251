import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'root/tailwind.config.js';
import { useCallback, useEffect, useMemo, useState } from 'react';

const config = resolveConfig(tailwindConfig);

const breakpoints = Object.entries(config.theme.screens)
  .map(([name, size]) => [name, Number(size.replace('px', ''))])
  .sort((a, b) => b[1] - a[1])
  .map(([name, size]) => [name, window.matchMedia(`(min-width: ${size}px)`)]);

const useResponsiveVariant = (variant) => {
  const scopedVariants = useMemo(() => {
    let variantMap;
    if (typeof variant === 'object') {
      variantMap = variant;
    } else if (typeof variant === 'string') {
      const variantList = variant.split(' ');

      if (variantList.length === 1) {
        return [[variant, { matches: true }]];
      }

      variantMap = variantList.reduce((agg, next) => {
        const [bp, value] = next.split(':');
        if (!value) {
          agg.default = bp;
        } else {
          agg[bp] = value;
        }

        return agg;
      }, {});
    } else {
      throw new Error('Unsupported variant map type');
    }

    const relevantBreakpoints = [
      ...breakpoints,
      ['default', { matches: true }],
    ].reverse();

    const scoped = [];

    relevantBreakpoints.forEach(([bp, query], index) => {
      scoped.push([variantMap[bp] ?? scoped[index - 1][0], query]);
    });
    return scoped.reverse();
  }, [variant]);

  const getValue = useCallback(() => {
    const index = scopedVariants.findIndex(([, query]) => query?.matches);
    return scopedVariants[index][0];
  }, [scopedVariants]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    scopedVariants.forEach(([, query]) => {
      if (query?.addListener) {
        query.addListener(handler);
      }
    });
    return () =>
      scopedVariants.forEach(([, query]) => {
        if (query?.removeListener) {
          query.removeListener(handler);
        }
      });
  }, [getValue, scopedVariants]);

  return value;
};

export default useResponsiveVariant;
