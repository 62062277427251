import React, { useLayoutEffect, useState } from 'react';
import states from 'src/lib/states';
import get from 'lodash.get';

import styles from './AutocompleteAddress.module.css';

import { Input, MaskedInput, Select } from 'src/common';
import AddressInput from 'src/common/AddressInput/AddressInput';

const AutocompleteAddress = ({
  name,
  label,
  isHistorical = false,
  formState: { errors },
  control,
  register,
  column = false,
  optional = false,
  setFocus,
  setValue,
}) => {
  const [manual, setManual] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);

  useLayoutEffect(() => {
    if (isHistorical) {
      setManual(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (manual) {
      setStateOptions(states);
      setFocus(`${name}.street`);
    }
  }, [manual, name, setFocus]);

  const enterManually = () => {
    setManual(true);
    setValue(`${name}.manual`, true);
  };

  const labelName = label ? `${label} ` : '';
  return (
    <div className={styles.container}>
      {manual ? (
        <>
          <div className={styles.row}>
            <div className={styles.col}>
              <Input
                label={labelName + 'Street'}
                containerClassName={styles.inputContainer}
                error={get(errors, `${name}.street.message`)}
                {...register(`${name}.street`, {
                  required: {
                    value: !optional,
                    message: 'Required',
                  },
                })}
                autoComplete="street-address"
                optional={optional}
              />
            </div>
            <div className={styles.col}>
              <Input
                label={labelName + 'City'}
                containerClassName={styles.inputContainer}
                error={get(errors, `${name}.city.message`)}
                {...register(`${name}.city`, {
                  required: {
                    value: !optional,
                    message: 'Required',
                  },
                })}
                autoComplete="address-level2"
                optional={optional}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Select
                label={labelName + 'State'}
                name={`${name}.state`}
                control={control}
                error={get(errors, `${name}.state.message`)}
                options={stateOptions}
                {...register(`${name}.state`, {
                  required: {
                    value: !optional,
                    message: 'Required',
                  },
                })}
                autoComplete="address-level1"
                optional={optional}
              />
            </div>
            <div className={styles.col}>
              <MaskedInput
                label={labelName + 'Zip Code'}
                name={`${name}.zip`}
                control={control}
                validLength={5}
                error={get(errors, `${name}.zip.message`)}
                mask="99999"
                rules={{
                  required: {
                    value: !optional,
                    message: 'Required',
                  },
                }}
                autoComplete="postal-code"
                optional={optional}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.col}>
          <AddressInput
            label={
              <>
                {labelName + 'Address'}
                <button
                  type="button"
                  onClick={enterManually}
                  className={styles.button}
                >
                  (Can't find your address?)
                  <span className="sr-only">
                    Activating this element will cause content on the page to be
                    updated.
                  </span>
                </button>
              </>
            }
            name={`${name}`}
            containerClassName={styles.addressInput}
            column={column}
            control={control}
            error={get(errors, `${name}.message`)}
            rules={{
              required: {
                value: !optional,
                message: 'Required',
              },
            }}
            autoComplete="street-address"
          />
        </div>
      )}
    </div>
  );
};

export default AutocompleteAddress;
