import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const identityTransform = {
  input: (v) => v,
  output: (v) => v,
};

export const dateTransform = {
  input: (v) => v && dayjs(v).format('MM/DD/YYYY'),
  output: (v) => {
    const date = dayjs(v);

    return v && date.isValid() ? date.format('YYYY-MM-DD') : null;
  },
};

export const timeTransform = {
  input: identityTransform.input,
  output: (v) => (v ? dayjs(v, 'HH:mm').format('HH:mm:ss') : null),
};
