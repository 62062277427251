import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import styles from './AddressInput.module.css';
import { DropdownMenu } from 'src/common';
import { useDebouncedSearch } from 'src/lib/hooks';
import config from 'src/config';
import debugData from 'src/lib/debugData';
import AptInput from './AptInput';

const maxrecords = 50;

const defaultTransform = {
  input: (value) =>
    value && {
      Address1: value.street,
      City: value.city,
      State: value.state,
      ZipCode: value.zip,
      formatted: `${value.street} , ${value.city}, ${value.state} ${value.zip}`,
      apt: null,
      SuiteName: null,
      SuiteList: [],
      SuiteCount: 0,
    },
};

const AddressInput = ({
  name,
  label,
  placeholder = '',
  defaultValue = null,
  control,
  rules,
  disabled,
  error,
  containerClassName,
  column = false,
  transform,
}) => {
  const { setInputText, search } = useDebouncedSearch(async (value) => {
    const { data } = await axios.get(config.melissaData.expressEntryEndpoint, {
      params: {
        id: config.melissaData.licenseKey,
        format: 'json',
        maxrecords,
        ff: value,
        filterterm: 'state',
        filterlist: 'FL',
        listwhite: true,
      },
    });

    const addresses = data.Results.map(
      ({
        Address: {
          AddressLine1,
          City,
          State,
          PostalCode,
          SuiteName: suiteName,
          SuiteList,
          SuiteCount,
        },
      }) => {
        const ZipCode = PostalCode.substr(0, 5);
        const SuiteName =
          SuiteList.length > 1 && SuiteList[0] !== '' && suiteName !== '#'
            ? suiteName || SuiteList[0].split(' ')[0]
            : '';

        return {
          Address1: AddressLine1,
          City,
          State,
          ZipCode,
          SuiteName,
          SuiteList,
          SuiteCount,
          formatted: `${AddressLine1}, ${City}, ${State} ${ZipCode}`,
          apt: null,
        };
      }
    );

    return addresses.filter(
      (addr) =>
        !addresses.find(
          (a) =>
            a.formatted === addr.formatted &&
            a.SuiteCount !== addr.SuiteCount &&
            addr.SuiteCount === 0
        )
    );
  }, {});
  const [aptError, setAptError] = useState(null);
  const transformIn = transform?.input ?? defaultTransform.input;

  const defaultSelectedItem = transformIn(defaultValue);

  const validate = (val) => {
    if (selectedItem?.SuiteCount > 1 && !val?.apt) {
      setAptError(`Required`);
      return false;
    } else if (selectedItem?.SuiteCount > 1 && !selectedItem.apt) {
      const index = selectedItem?.SuiteList?.findIndex(
        (option) => option.toLowerCase() === val?.apt?.toLowerCase()
      );
      if (index <= -1 && val?.apt) {
        setAptError('Invalid option');
        return false;
      } else {
        setAptError(null);
        return true;
      }
    }
  };
  const {
    field: { onChange, onBlur, ref, value },
  } = useController({
    name,
    control,
    rules: { validate, ...rules },
    defaultValue: defaultSelectedItem,
  });

  const {
    isOpen,
    selectedItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: search.result || [],
    itemToString: (item) => item?.formatted,
    onInputValueChange: (change) => {
      if (change.selectedItem?.formatted !== change.inputValue) {
        setInputText(change.inputValue);
        onChange(null);
      }
    },
    onSelectedItemChange: (change) => {
      const { Address1, City, State, ZipCode } = change.selectedItem;
      onChange({
        street: Address1,
        city: City,
        state: State,
        zip: ZipCode,
      });
    },
    defaultSelectedItem,
  });
  const handleAptChange = (apt) => {
    const { Address1, City, State, ZipCode } = selectedItem;
    onChange({
      street: `${Address1} ${apt}`,
      city: City,
      state: State,
      zip: ZipCode,
      apt: apt,
    });
  };

  return (
    <div
      className={classNames(
        styles.container,
        !column && styles.row,
        containerClassName
      )}
    >
      <div
        className={classNames(styles.dropdownContainer, {
          [styles['selectedItem']]: selectedItem?.SuiteCount > 1 && !column,
        })}
      >
        {label && (
          <label className={styles.label} {...getLabelProps()}>
            {label}
          </label>
        )}
        <div {...getComboboxProps()}>
          <input
            type="text"
            className={classNames(styles.input, error && styles.error)}
            {...getInputProps({
              name,
              placeholder,
              disabled,
              onBlur,
              ref,
            })}
            {...debugData({ input: 'address' })}
            autoComplete="off"
            aria-invalid={error ? 'true' : 'false'}
            {...(error && { 'aria-describedby': `${name}-error` })}
          />
          <DropdownMenu
            isOpen={isOpen}
            disabled={disabled}
            error={error}
            options={search?.result || []}
            searching={search?.loading}
            highlightedIndex={highlightedIndex}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            getItemValue={(item) => item.formatted}
          />
        </div>
        <div className={styles.errorContainer}>
          {error && (
            <span id={`${name}-error`} className={styles.textError}>
              {error}
            </span>
          )}
        </div>
      </div>
      {selectedItem?.SuiteCount > 1 && (
        <AptInput
          name={name + 'UnitNumber'}
          label={`${selectedItem.SuiteName || 'Unit'} #`}
          disabled={disabled}
          aptList={selectedItem.SuiteList}
          onChange={handleAptChange}
          error={aptError}
          column={column}
          value={value?.apt ?? ''}
        />
      )}
    </div>
  );
};

export default AddressInput;
