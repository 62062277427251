import React, { useEffect } from 'react';
import { Page } from 'src/common';
import { useAuth0 } from 'src/lib/hooks';
import Common from './Common';
const EmailVerification = ({ prevPage, nextPage }) => {
  const { user } = useAuth0();

  useEffect(() => {
    const continues = async () => {
      if (user?.hasSession) {
        await nextPage(null, { skipUpdate: true });
      }
    };
    continues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasSession]);

  return (
    <Page title="Email Verification">
      <Common onBack={prevPage} />
    </Page>
  );
};

EmailVerification.Representative = (props) => <EmailVerification {...props} />;
EmailVerification.Representative.displayName = 'RepresentativeVerification';
EmailVerification.PolicyHolder = (props) => <EmailVerification {...props} />;
EmailVerification.PolicyHolder.displayName = 'PolicyHolderVerification';
EmailVerification.Resumption = (props) => <Common {...props} />;
EmailVerification.Resumption.displayName = 'ResumptionVerification';

export default EmailVerification;
