import React from 'react';
import { useController } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { Input } from 'src/common';

const noop = (v) => v;

const getValueLength = (value) => {
  return value.replace(/_/g, '').length;
};
const validateMaskedLength = (value, lengthValue) => {
  const valueLength = getValueLength(value);

  return lengthValue === valueLength;
};

const validateIsNotEmpty = (value) => {
  const valueLength = getValueLength(value);
  return 0 !== valueLength;
};

const MaskedInput = ({
  name,
  control,
  mask,
  validLength,
  rules,
  defaultValue,
  transform,
  ...props
}) => {
  // const transformIn = transform?.input ?? noop;
  const transformOut = transform?.output ?? noop;
  const { field } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate: {
        checkLength: (value) => {
          if (validLength) {
            return validateMaskedLength(value, validLength) || 'Required';
          }
        },
        checkEmpty: (value) => {
          if (rules.required) {
            return validateIsNotEmpty(value) || 'Required';
          }
        },
      },
      setValueAs: transformOut,
    },
    defaultValue: defaultValue ?? '',
  });

  // useEffect(() => {
  //   field.onChange(transformIn(field.value));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <ReactInputMask
      mask={mask}
      value={field.value}
      onChange={field.onChange}
      disabled={props.disabled}
    >
      {(maskedInputProps) => (
        <Input
          {...maskedInputProps}
          ref={React.createRef(field.ref)}
          name={name}
          {...props}
        />
      )}
    </ReactInputMask>
  );
};

export default MaskedInput;
