import { useAuth0, useModel } from '.';
import analytics, { EVENTS } from 'src/lib/analytics';

const handler = async (err, logout, fnol) => {
  const statusCode = err?.statusCode ?? err?.response?.status;

  if ([500, 503, 504, 408, 429].includes(statusCode)) {
    analytics.track(EVENTS.UNEXPECTED_ERROR, fnol);
    return 'We ran into an issue. Please try again in a few minutes.';
  } else if (statusCode === 401) {
    analytics.track(EVENTS.UNEXPECTED_ERROR, fnol);
    await logout('/resume');
    return 'Something went wrong. Please try again later.';
  } else {
    analytics.track(EVENTS.UNEXPECTED_ERROR, fnol);
    return (
      err?.response?.data?.error ??
      err?.response?.data?.Error ??
      err?.response?.data?.Message ??
      'Something went wrong. Please try again later.'
    );
  }
};

const useErrorHandling = () => {
  const { logout } = useAuth0();
  const fnol = useModel.fnol();

  const handleError = (err) => handler(err, logout, fnol);

  return handleError;
};

export default useErrorHandling;
