import React from 'react';
import { Page } from 'src/common';
import styles from './Submitted.module.css';

const Submitted = () => {
  return (
    <Page
      stage="We Have Received Your Information"
      titleClassName={styles.title}
    >
      <Page.Section>
        <div className={styles.information}>
          <p>Thank you for submitting your claim!</p>
          <p>Your information has been received and is being reviewed.</p>
          <p>
            A claims examiner will contact you as soon as possible. You will
            receive an email soon that will contain your claim examiner’s name
            and phone number. We are generally able to contact our insureds
            within one business day. However, if you have submitted a claim as a
            result of a recent hurricane or tropical storm, it may take longer
            for us to respond due to the volume of claims being reported.
          </p>
          <p>You may now close this tab.</p>
        </div>
      </Page.Section>
    </Page>
  );
};

Submitted.displayName = 'Submitted';

export default Submitted;
